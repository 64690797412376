<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback"/>
        <span>查询提现</span>
      </div>
    </div>
    <div class="zonjin_box">
      <div class="zongjine">
        <span>总金额(元)：</span>
        <span>{{ balance }}</span>
      </div>
      <div class="righs">
        <span>未结算金额(元)：</span>
        <span class="dxxz">{{ freezeAmount }}</span>
      </div>
    </div>
    <div class="tixianBox">
      <div class="tixian_insx">
        <div>可提现金额(元)</div>
        <div class="jine">{{ availableAmount }}</div>
      </div>
      <van-button
          class="rex_button"
          size="small"
          type="default"
          @click="goWithdraw"
      >提 现
      </van-button
      >
    </div>
    <div>
      <div class="txjl">提现记录</div>
      <div class="txjlData" v-if="!showkong">
        <div class="txjlData_box" v-for="item in getCashoutData" :key="item.id">
          <div>
            流水号：<span>{{ item.orderNo }}</span>
          </div>
          <div>
            金额：<span>{{ item.amount }}</span>
          </div>
          <div v-if="item.status == 0">
            状态：<span class="danger">失败</span>
          </div>
          <div v-if="item.status == 1">
            状态：<span class="success">成功</span>
          </div>
          <div v-if="item.status == 2">
            状态：<span class="lanse">提现中</span>
          </div>
          <div>
            时间：<span>{{ item.createTime }}</span>
          </div>
        </div>
      </div>
      <div class="inxx_nimg" v-if="showkong">
        <img src="@/assets/images/indstixiank.png" alt=""/>
      </div>
      <div class="logindata" v-if="showText">{{ textLogin }}</div>
    </div>

    <!-- 验证码弹框 -->
    <van-popup v-model="showyz" round class="insa">
      <van-field
          class="lsbes"
          v-model="txmoney"
          label="提现金额"
          type="digit"
          placeholder="请输入提现金额"
      />
      <van-button class="subsime" size="normal" type="primary" @click="sublis"
      >确认
      </van-button
      >
    </van-popup>
    <!-- 结束 -->
  </div>
</template>

<script>
import {getAccountInfo, getCashoutList, cashout} from "@/request/api";

export default {
  data() {
    return {
      showkong: false,
      pageNum: 1,
      pageSize: "8",
      showText: false,
      textLogin: "我是有底线的~~~~",
      balance: "",
      freezeAmount: "",
      availableAmount: "",
      showyz: false,
      txmoney: "",
      loginShow: true,
      getCashoutData: "",
    };
  },
  created() {
    this.getuserInfo();
    this.getCashout();
  },
  methods: {
    getscroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离c
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        if (this.loginShow) {
          this.pageNum++;
          this.showText = true;
          this.getCashout();
        } else {
        }
      }
    },

    // 获取当前登录用户金额信息
    getuserInfo() {
      getAccountInfo().then((res) => {
        console.log(res);
        if (res.code == "200") {
          console.log(res);
          this.balance = res.data.balance;
          this.availableAmount = res.data.availableAmount;
          this.freezeAmount = res.data.freezeAmount;
        }
      });
    },
    // 提现列表
    getCashout() {
      getCashoutList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          console.log(res);
          if (res.data.length < 8 && res.data.length != 0) {
            this.loginShow = false;
            this.showText = true;
            this.textLogin = "我是有底线的~~~~";
          }

          this.getCashoutData = [...this.getCashoutData, ...res.data];
          // this.getCashout

          if (this.getCashoutData.length == 0) {
            this.showkong = true;
            this.textLogin = "";
          } else {
            this.showkong = false;
          }
        }
      });
    },
    // 点击提现弹框
    goWithdraw() {
      this.showyz = true;
    },

    // 提现
    sublis() {
      this.showyz = false;
      let that = this;
      this.$dialog
          .alert({
            title: "提现",
            message: "是否确认提现！",
            beforeClose,
            showConfirmButton: true, //确认按钮
            showCancelButton: true, //取消按钮
          })
          .catch(() => {
          });

      function beforeClose(action, done) {
        if (action === "confirm") {
          // 确认按钮
          that.tixian();
          done();
        } else {
          that.txmoney = "";
          done();
        }
      }
    },
    // 提现接口
    tixian() {
      cashout({
        amount: this.txmoney,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$notify({
            type: "success",
            message: res.message,
          });
          this.getCashout;
        } else {
          this.$notify({
            type: "warning",
            message: res.message,
          });
        }
        this.txmoney = "";
      });
    },
    goback() {
      this.$router.push("/mine");
    },
  },
};
</script>

<style lang="scss" scoped>
.tixianBox {
  width: 100%;
  height: 4.6rem;
  background: #6699cc;
  color: #ffffff;

  .tixian_insx {
    width: 40%;
    display: inline-block;
    padding: 0.6rem;

    .jine {
      margin-top: 0.5rem;
      padding-left: 1.5rem;
      font-size: 1.5rem;
    }
  }

  .rex_button {
    width: 3rem;
    float: right;
    // vertical-align: middle;
    margin: 1.55rem;
  }
}

.txjl {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 550;
  letter-spacing: 0.1rem;
  margin-left: 0.8rem;
  color: #ff9900;
}

.inxx_nimg {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  margin-top: 9rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.logindata {
  height: 2rem;
  line-height: 2rem;
  width: 100%;
  text-align: center;
  color: #e6e6e6;
}

.txjlData {
  padding: 0.6rem;
  margin-bottom: 0.8rem;
}

.insa {
  width: 80%;

  .lsbes {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .subsime {
    margin-top: 1rem;
    margin-left: 34%;
    width: 35%;
    margin-bottom: 1rem;
  }
}

.zonjin_box {
  width: 100%;
  height: 2rem;
  padding-top: 0.8rem;
  background: #6699cc;
  color: #ffffff;

  .zongjine {
    display: inline-block;
    margin-left: 0.4rem;
  }

  .righs {
    display: inline-block;
    float: right;
    margin-right: 0.6rem;

    .dxxz {
      color: #cc3333;
    }
  }
}

.txjlData_box {
  width: 90%;
  padding: 0.6rem;
  background: #cccccc;
  margin: 0 auto;
  border-radius: 0.5rem;
  color: #ffffff;

  .danger {
    color: red;
  }

  .success {
    color: #07c160;
  }

  .lanse {
    color: #1989fa;
  }
}
</style>
